import React, {useState} from 'react'
import { Box, makeStyles, TextField, Button } from '@material-ui/core'


const useStyle = makeStyles({
    banner:{
       backgroundImage:'url(images/banner/slider-1.jpg)',
       backgroundRepeat:'no-repeat',
       backgroundSize: '100% 100%',
       width:'100%',
       height: '70Vh',
    },
    banner_overlay :{
        background: 'rgb(0 0 0 / 12%)',
       width: '100%',
       height: '100%',
       display: 'flex',
       justifyContent: 'center',
       alignItems: 'center', 
    },
    serarch:{
        backgroundColor: '#ffffff9e',
        padding: '15px',
        borderRadius: '3px',
    },
    input_box :{
       backgroundColor: '#fff',
       borderRadius: '5px',
    }
    

})

const Banner = () => {
const classes = useStyle();

  return (
    <>
     
      <div className={classes.banner}>
         <div className={classes.banner_overlay}>
         </div>
      </div>
    </>
   
  )
}


export default Banner;