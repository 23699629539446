import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles({
    about:{
        padding:'40px',
        '& h3':{
            fontSize:'30px',
            fontWeight:'500',
            color:'#333',
            marginTop:'0px',
            marginBottom: '20px',
            textAlign:'center',
        },
        '& p': {
            textAlign:'justify',
            fontSize:'16px',
            lineHeight:'25px',
            margin: '10px',
        }
    },
    
})

const About = () => {
 const classes = useStyle();
  return (
    <div className={classes.about}>
       <h3>About Us</h3>
       <p>Although many of us have been "tourists" at some point in our lives, defining what tourism actually is can be difficult. Tourism is the activities of people traveling to and staying in places outside their usual environment for leisure, business or other purposes for not more than one consecutive year. Tourism is a dynamic and competitive industry that requires to support customers' changing needs and desires, as the customer’s satisfaction, safety and enjoyment are particularly the focus of tourism businesses. Moreover, now a days peoples are looking for new and natural destination for tourism and Indian villages are most attractive places for urban citizens of India and for worldwide populations. </p>
       <p>On the other hand, the rehabilitation is a big need and challenge for the country and rehabilitation of retired personnel is more challenging for the Government as well as for the Society.</p>
       <p>Ardh Sainik Homz is an Sainik Welfare initiative to assist the Government for rehabilitation of forces personnel and to facilitate retired force personnel and their family members to provide suitable work at their home/village.</p>
    </div>
  )
}

export default About