import React from 'react'
import { Box, Typography, makeStyles, Grid } from '@material-ui/core';
import { AddLocation } from '@material-ui/icons';
import { hotelData } from '../constent/data';


const useStyle = makeStyles({
    card:{
        margin: '10px',
        overflow: 'hidden',
        cursor: 'pointer',
    },
    card__img:{
       objectFit: 'fill',
       minWidth: '300px',
       minHeight: '200px',
       width: '100%',
    },
    card__info:{
       borderRadius: '5px',
       border: '1px',
    },
    card__info__name:{
      fontSize:'16px',
      lineHeight:'20px',
      fontWeight:'400',
      color:'#333',
    },
    card__info__location:{
      fontSize:'14px',
      lineHeight:'22px',
      fontWeight:'300',
      color:'#666'
    },
    card__info__price:{
      fontSize:'18px',
      lineHeight:'22px',
      fontWeight:'500',
      marginTop: '4px',
      color:'#0e9c00',
    }
})

const Card = () => {
  const classes = useStyle();
  return (
    <>
    {
      hotelData.map(item =>(
      <Grid item lg={3}>
        <div className={classes.card}>
            <img className={classes.card__img} src={item.image} alt=''></img>
            <div className={classes.card__info}>
              <span className={classes.card__info__name}>{item.name}</span>
              <Typography className={classes.card__info__location}>{item.location}</Typography>
              <Typography className={classes.card__info__price}>{item.price}</Typography>
            </div>
        </div>
      </Grid>
      ))
    }
  </>
  )
}

export default Card