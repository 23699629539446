
import React from 'react'
import NavBar from '../../components/NavBar';
import Banner from './Banner';
import Destinations from './Destinations';
import MidSection from './MidSection';



const Home = () => {
  return (
    <>
    <Banner/>
    <Destinations/>
    <MidSection/>
    </>
  )
}

export default Home;

