import React from 'react'

const Destinations = () => {
  return (
    <div className='destinations'>
            <h1>Top destinations in India</h1>
            <div className="img-container">
                <img className='span-3 image-grid-row-2' src="images/dis-1.jpg" alt="/"/>
                <img src="images/dis-2.jpg" alt="/"/>
                <img src="images/dis-3.jpg" alt="/"/>
                <img src="images/dis-4.jpg" alt="/"/>
                <img src="images/dis-5.jpg" alt="/"/>
            </div>
    </div>
  )
}

export default Destinations