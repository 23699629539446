import Header from "./components/Header";
import Home  from "./pages/home/Home";
import { Routes, Route, Link } from "react-router-dom";
import Footer from "./components/Footer";
import About from "./pages/About";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  return (
    <>
    <Header/>
    <Routes>
       <Route exact path="/" element={<Home/>}/>
       <Route path="/about" element={<About/>}/>
       <Route path="/terms-conditions" element={<TermsConditions/>}/>
       <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
    </Routes>
    <Footer/>
    </>
  );
}

export default App;
