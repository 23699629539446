import React from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyle = makeStyles({
    footer:{
      backgroundColor:'#f8f9fa',
      borderTop:'1px solid #f4f4f4',
      padding:'20px 50px',
    },
    footer__title:{
      fontSize:'18px',
      fontWeight:'500',
      lineHeight:'24px',
      color:'#333',
    },
    footer__listGroup:{
      listStyle:'none',
      padding:'0px',
      margin:'0px'
    },
    footer__list:{
      display:'block',
      margin: '0 0 10px 0',
      lineHeight: '15px',
      fontSize: '14px',
      whiteSpace: 'normal',
      listStyleType: 'none',
    },
    footer__link:{
      textDecoration:'none',
      color:'#555',
    },
    footer__copyright:{
      backgroundColor:'#eff1f4',
      padding:'10px',
      textAlign:'center',
      fontSize:'14px',
    }
})

const Footer = () => {
  const classes = useStyle();
  return (
    <footer>
      <div className={classes.footer}>
      <Grid container lg={12} sm={12} md={12} xs={12}>
        <Grid item lg={3}>
          <h3 className={classes.footer__title}>About Us</h3>
          <ul className={classes.footer__listGroup}>
            <li className={classes.footer__list}><Link className={classes.footer__link} to='/about'>About</Link></li>
            <li className={classes.footer__list}><Link className={classes.footer__link} to='#'>Team</Link></li>
            <li className={classes.footer__list}><Link className={classes.footer__link} to='#'>Career</Link></li>
            <li className={classes.footer__list}><Link className={classes.footer__link} to='#'>Blog</Link></li>
          </ul> 
        </Grid>
        <Grid item lg={3}>
          <h3 className={classes.footer__title}>Destinations</h3> 
          <ul className={classes.footer__listGroup}>
          <li className={classes.footer__list}><Link className={classes.footer__link} to='#'>Holiday Packages</Link></li>
            <li className={classes.footer__list}><Link className={classes.footer__link} to='#'>Travel Agents</Link></li>
            <li className={classes.footer__list}><Link className={classes.footer__link} to='#'>Hotels</Link></li>
            <li className={classes.footer__list}><Link className={classes.footer__link} to='#'>Cities</Link></li>
          </ul> 
        </Grid>
        <Grid item lg={3}>
          <h3 className={classes.footer__title}>Partner with us</h3>
          <ul className={classes.footer__listGroup}>
            <li className={classes.footer__list}><Link className={classes.footer__link} to='#'>Ardhsainikgroup.com</Link></li>
            <li className={classes.footer__list}><Link className={classes.footer__link} to='#'>Ardhsainikcanteen.com</Link></li>
            <li className={classes.footer__list}><Link className={classes.footer__link} to='#'>Ardhsainiksecurity.com</Link></li>
            <li className={classes.footer__list}><Link className={classes.footer__link} to='#'>Abhhyam.com</Link></li>
          </ul> 
        </Grid>
        <Grid item lg={3}>
          <h3 className={classes.footer__title}>Support Us</h3>
          <ul className={classes.footer__listGroup}>
            <li className={classes.footer__list}><Link className={classes.footer__link} to='/terms-conditions'>Terms And Conditions</Link></li>
            <li className={classes.footer__list}><Link className={classes.footer__link} to='/privacy-policy'>Privacy Policy</Link></li>
            <li className={classes.footer__list}><Link className={classes.footer__link} to='#'>Guest Policies</Link></li>
            <li className={classes.footer__list}><Link className={classes.footer__link} to='#'>Contact </Link></li>
          </ul> 
        </Grid>
      </Grid>
      </div>
      <Grid container lg={12} sm={12} md={12} xs={12}>
          <Grid item lg={12}>
            <div className={classes.footer__copyright}>
              <p>Copyright©2022 AsHomz.com.All Right Reserved</p>
            </div>
          </Grid>
      </Grid>
    </footer>
  )
}

export default Footer