import React, {useState} from "react";
import {Button,makeStyles} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import Search from "./Search";
import { Link } from "react-router-dom";


const useStyle = makeStyles({
    header:{
      width: '100%',
      backgroundColor:'#fff',
      borderBottom: '1px solid #0000001a',
      boxShadow: '0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 2px 0 rgb(0 0 0 / 3%)',
      display: 'flex',
      justifyContent: 'space-between',
      position: 'sticky',
      top: '0',
      zIndex: '100',
      padding: '10px 30px',
      boxSizing: 'border-box',
    },
    header__logo:{
      objectFit: 'contain',
      width: '120px',
    },
    header__center:{
      display: 'flex',
      flex: '1',
      alignItems: 'center',
      maxWidth: 'fit-content',
      padding: '10px',
      height: '25px',
      border: '1px solid #ddd',
      borderRadius: '999px',
    },
    header__secrch:{
      border: 'none',
      padding: '10px',
      outline: 'none',
      width: '250px',

    },
    header__right:{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }
})

function Header (){
  const classes = useStyle();
  const [showSearch, setShowSearch] = useState(false);
    return(
        <div className={classes.header}>
              <div>
                  <Link to="/"><img src="images/homz_logo.png" alt="" className={classes.header__logo}></img></Link>
              </div>
              <div className={classes.header__center}>
                <input type="text" onClick={() => setShowSearch(!showSearch)} className={classes.header__secrch} placeholder="Srarch for Hotels"/>
                <SearchIcon/>
                {showSearch && <Search />}

              </div>
              <div className={classes.header__right}>
                <Button variant="outlined" color="primary" style={{marginRight:10 }}>Sign in</Button>
                <Button variant="outlined" color="primary">Create account</Button>
              </div>
        </div>
    );
}

export default Header;