import { Box, Typography, makeStyles } from '@material-ui/core';
import React from 'react'
import { navData } from '../constent/data';

const useStyle = makeStyles({
   navbar:{
     display: 'flex',
     justifyContent: 'space-around',
     padding: '10px',
     textAlign: 'center',
   },
   city_icon:{
     width: '50px',
     height: '50px',
     borderRadius: '100%'
   }
})

const NavBar = () => {
  const classes = useStyle()
  return (
    <div className={classes.navbar}>
        {
          navData.map(item => (
             <div>
              <img src={item.city_img} alt='' className={classes.city_icon}></img>
              <Typography>{item.city_name}</Typography>
             </div>
          ))
        }
        
    </div>
  )
}


export default NavBar;