import React, { useState } from 'react'
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from 'materialui-daterange-picker';
import { Button, Typography,makeStyles } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import { useNavigate } from "react-router-dom";


const useStyle = makeStyles({
    search:{
        position: 'absolute',
        top: '35px',
        left: '25%',
        width: '100vw',   
    },
    search__text:{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '550px',
      padding: '10px',
      backgroundColor: '#fff',
      position: 'absolute',
      left: '0px',
      top: '69px',
    },
    search__input:{
       width: '530px',
       padding: '20px',
       position: 'absolute',
       left: '0px',
       height: '30px',
       top: '112px',
       border: 'none',
    },
    search__button:{
        position: 'absolute',
        left: '0px',
        top: '32px',
        textTransform: 'inherit',
        backgroundColor: 'green',
        color: '#fff',
        width: '570px',
        borderRadius: '0px',
        
    }
})

const Search = () => {
  const classes = useStyle();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  
  const selectionRange = {
      startDate: startDate,
      endDate: endDate,
      key: "Selection",
  };

  function handleSelect(ranges){
      setStartDate(ranges.selection.startDate);
      setEndDate(ranges.selection.endDate);
  }
  return (
    <div className={classes.search}>
      <DateRangePicker ranges={[
          selectionRange
      ]} onChange={handleSelect} />

      <Typography className={classes.search__text} h3>Number of guests <PeopleIcon/></Typography>

      <input className={classes.search__input} min={0} defaultValue={2} type="number"/>

      <Button className={classes.search__button} onClick={() => navigate.push('/search')}>Search AsHomz</Button>

    </div>
  )
}

export default Search