export const navData = [
    {
        city_img: 'images/city/delhi.jpg',
        city_name: 'Delhi',
    },
    {
        city_img: 'images/city/gurugram.jpg',
        city_name: 'Gurugram',
    },
    {
        city_img: 'images/city/noida.jpg',
        city_name: 'Noida',
    },
    {
        city_img: 'images/city/udaipur.jpg',
        city_name: 'Udaipur',
    },
    {
        city_img: 'images/city/mumbai.jpg',
        city_name: 'Mumbai',
    },
    {
        city_img: 'images/city/pune.jpg',
        city_name: 'Pune',
    },
    {
        city_img: 'images/city/hyderabad.jpg',
        city_name: 'Hyderabad',
    },
    {
        city_img: 'images/city/bangalore.jpg',
        city_name: 'Bangalore',
    },
    {
        city_img: 'images/city/chennai.jpg',
        city_name: 'Chennai',
    },
    {
        city_img: 'images/city/kolkata.jpg',
        city_name: 'Kolkata',
    }
    
];

export const hotelData=[
    {
    image:'images/hotels/image-1.jpg',
    name:'Ardh Sainik Homz Inn',
    location:'New Delhi and NCR',
    price:'INR 1199.00',
    },
    {
    image:'images/hotels/image-2.jpg',
    name:'Ardh Sainik Homz Inn',
    location:'New Delhi and NCR',
    price:'INR 1199.00',
    },
    {
    image:'images/hotels/image-3.jpg',
    name:'Ardh Sainik Homz Inn',
    location:'New Delhi and NCR',
    price:'INR 1199.00',
    },
    {
    image:'images/hotels/image-4.jpg',
    name:'Ardh Sainik Homz Inn',
    location:'New Delhi and NCR',
    price:'INR 1199.00',
    },
    {
    image:'images/hotels/image-5.jpg',
    name:'Ardh Sainik Homz Inn',
    location:'New Delhi and NCR',
    price:'INR 1199.00',
    },
    {
    image:'images/hotels/image-6.jpg',
    name:'Ardh Sainik Homz Inn',
    location:'New Delhi and NCR',
    price:'INR 1199.00',
    },
    {
    image:'images/hotels/image-7.jpg',
    name:'Ardh Sainik Homz Inn',
    location:'New Delhi and NCR',
    price:'INR 1199.00',
    },
    {
    image:'images/hotels/image-8.jpg',
    name:'Ardh Sainik Homz Inn',
    location:'New Delhi and NCR',
    price:'INR 1199.00',
    },
];