import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles({
    privacy__policy:{
      padding:'40px',
      '& h3':{
        fontSize:'30px',
        fontWeight:'500',
        color:'#333',
        marginTop:'0px',
        marginBottom: '20px',
        textAlign:'center',
      },
      '& p': {
          textAlign:'justify',
          fontSize:'16px',
          lineHeight:'25px',
          margin: '10px',
      }
    },
    
})

const PrivacyPolicy = () => {
  const classes = useStyle();
  return (
    <div className={classes.privacy__policy}>
       <h3 className={classes.privacy__policyTitle}>Privacy Policy</h3>
       <p>We are committed to protecting your privacy. We will only use the information that we collect about you lawfully. We collect information about you for 2 reasons:</p>
       <p>Ardh Sainik Homz (AsHomz) is a Village Tourism concept initiative of Ardh Sainik Welfare Trust (Regd.) for rehabilitation of Soldiers Community. Through this the house of Soldiers in any village of India will be provided for stay at Village and to enjoy the village life with the help of that Soldiers. </p>
       <p>We will take the booking of Guest through this application those are interested to visit and stay at Village for some time to see and enjoy the village life, therefore, we need the actual location of the Guest to offer him nearby As Homz village area. </p>
       <p>Firstly, to process the booking order and secondly, to provide the best possible service.</p>
       <p>The type of information we will collect about you (Guest) normally includes Your Name, Address, Phone Number, Email Address, Nationality.</p>
       <p>We will never collect sensitive information about you without your explicit consent.</p>
       <p>The information we hold will be accurate and up to date.</p>
       <p>You can check the information that we hold about you by emailing us. If you find any inaccuracies we will delete or correct it promptly.</p>
       <p>The personal information which we hold will be held securely in accordance with our internal security policy and the law.</p>
       <p>We may use technology to track the patterns of behavior of visitors to our site. This can include using a “cookie” which would be stored on your browser.</p>
       <p>You can usually modify your browser to prevent this happening.</p>
       <p>The information collected in this way can be used to identify you unless you modify your browser settings. If you have any questions/comments about privacy, you should contact us at home@ardhsainik.com.</p>
    </div>
  )
}

export default PrivacyPolicy