import { Grid, makeStyles, Item, Typography,} from '@material-ui/core'
import React from 'react'
import Card from '../../components/Card'
const useStyle = makeStyles({
    midsection:{
        padding: '20px 40px 40px',
    },
    midsection_title:{
      fontWeight:'300',
      textAlign:'center',
    },
    midsection_img: {
        width: '100%',
    }
})

const MidSection = () => {
  const classes = useStyle()
  return (
   <div className={classes.midsection}>
        <h1 className={classes.midsection_title}>Featured homes recommended for you</h1>
        <Grid container lg={12} sm={12} md={12} xs={12}>
            <Card/>
        </Grid>
    </div>
  )
}

export default MidSection